<template>
    <div>
        <v-row v-if="!loading && !permissions.can_list">
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <p class="text-h5 mb-4">My Outlet Visiting</p>
                <p class="text-title mb-0">You do not have permission to view my outlet visiting</p>
            </v-col>
        </v-row>
        <template v-if="permissions.can_list">
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="pb-0 d-flex justify-space-between">
                    <h2 class="font-weight-regular">My Outlet Visiting</h2>
                    <v-tooltip bottom><template v-slot:activator="{ on, attrs }"><v-btn icon v-bind="attrs" v-on="on" :loading="loading" @click="get"><v-icon>mdi-refresh</v-icon></v-btn></template><span>Refresh</span></v-tooltip>
                </v-col>
                <v-layout align-center justify-center :style="getStyle()" v-if="loading">
                    <v-progress-circular indeterminate :width="3" :size="60" color="primary"></v-progress-circular>
                </v-layout>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="text-center" v-if="!loading">
                    <v-data-table hide-default-footer class="elevation-0" loading-text="Loading... Please wait..." no-data-text="No data available yet" :loading="loading" :items-per-page="100" :headers="headers" :items="items">
                        <template v-slot:item='{ item }'>
                            <tr>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.project"></td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.campaign"></td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.work_date"></td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.outlet"></td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.start_time"></td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.end_time"></td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.promoter.name"></td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.promoter.mobile_number"></td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.area"></td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center">
                                    <v-menu bottom offset-y v-if="hasMenu(item)">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn small icon v-bind="attrs" v-on="on">
                                                <v-icon>mdi-dots-horizontal</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-list dense>
                                            <v-list-item :to="getViewUrl(item.id)" target="_blank" v-if="item.can_edit">
                                                <v-list-item-title v-if="!item.is_submitted">Submit Feedback</v-list-item-title>
                                                <v-list-item-title v-if="item.is_submitted">View Feedback</v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </template>
    </div>
</template>


<script>

import { delay } from '@/utils/helper'

export default {
    name: 'DashboardOutletVisiting',
    data() {
        return {
            loading: true,
            isAllSelected: false,
            tab: null,
            permissions: {
                can_list: false
            },
            panel: [],
            selectedItems: [],
            dates: [],
            items: [],
            admins: [],
            headers: []
        }
    },
    mounted: function() {
        delay(this.get, 500)
    },
    methods: {
        get: function() {
            this.selectedItems = []
            this.$store.dispatch('epanel/dashboard/getMyOutletVisitingDashboard').then((response) => {
                this.dates = response.data.dates
                this.admins = response.data.admins
                this.items = response.data.items
                this.headers = response.data.headers
                this.permissions = response.data.permissions
                this.loading = false
            }).catch(() => {
                this.loading = false
            })
        },
        getStyle: function() {
            const height = this.$route.meta.has_bottom_navigation?2*56:56
            const minHeight = (window.innerHeight - height).toString() + "px"
            return {"min-height": minHeight}
        },
        getViewUrl: function(id) {
            return 'me/' + id + '/feedback'
		},
        hasMenu: function(item) {
            return item.can_edit
        }
    }
}
</script>


<style scoped>

</style>